<template>
  <div class="cont">
     <!-- 面包屑 -->
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>AIOT</el-breadcrumb-item>
           <el-breadcrumb-item>酒店管理</el-breadcrumb-item>
           <el-breadcrumb-item>PMS管理</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>
      <el-row class="content-box">
         <!-- 表头 -->
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>名称/编码：</label>
                  <el-input class="width-220" v-model="condition" :placeholder="$t('msg.room_name_code')"  clearable></el-input>
               </el-row>
               <el-button class="bg-gradient" type="primary" @click="handleQuery(true)" ><span v-text="$t('msg.search')">搜索</span></el-button>
               <el-button type="primary" @click="handleQuery(false)"  plain><span v-text="$t('msg.reset')">重置</span></el-button>
            </el-row>
         </el-row>
         <!-- 表格 -->
         <div class="table-box">
            <div class="table-head-btn">
               <el-row></el-row>
               <el-row class="right-btn">
                  <el-button class="bg-gradient" type="primary" @click="handleAdd" ><span v-text="$t('msg.add_pms')">新建PMS</span></el-button>
               </el-row>
            </div>
            <el-table
                  v-loading="loading"
                  :data="tableData"
                  highlight-current-row
                  style="width: 100%"
                  min-height="780"
                  max-height="780"
                  :stripe="true"
                  border fit
                  >
               <el-table-column :label="$t('msg.number')" width="60">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column :label="$t('msg.room_icon')" width="70">
                  <template slot-scope="scope">
                     <img v-if="scope.row.logo" :src="scope.row.logo" width="40" height="40" class="v-align-middle"/>
                     <img v-else src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" width="40" height="40" class="v-align-middle" alt="">
                  </template>
               </el-table-column>
               <el-table-column prop="pmsName" :label="$t('msg.pms_name')" min-width="110"></el-table-column>
               <el-table-column prop="pmsCode" :label="$t('msg.pms_code')" min-width="110"></el-table-column>
               <el-table-column prop="serviceName" :label="$t('msg.service_name')" min-width="150"></el-table-column>
               <el-table-column prop="contacts" :label="$t('msg.contact_person')" min-width="100"></el-table-column>
               <el-table-column prop="telephone" :label="$t('msg.contact_phone')"></el-table-column>
               <el-table-column prop="email" :label="$t('msg.e_mail')"></el-table-column>
               <el-table-column prop="address" :label="$t('msg.contact_address')" min-width="180"></el-table-column>
               <el-table-column :label="$t('msg.operating')" min-width="100" fixed="right">
                  <template slot-scope="scope">
                     <el-button  type="text" icon="el-icon-edit" @click="handleEdit(scope.row)" >
                     <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                     </el-button>
                     <el-button  type="text" style="color: #F56C6C;" icon="el-icon-delete" @click="handleDel(scope.row.id)" >
                     <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                     </el-button>
                  </template>
               </el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
         </div>
         <!-- 添加/编辑弹窗 -->
         <el-dialog :title="$t('msg.edit_pms')" :visible.sync="isShow" width="550px" :before-close="handleClose">
            <el-form class="form-dialog-box">
               <el-form-item required label="PMS名称">
                  <el-input  class="width-300" v-model="pmsName" :placeholder="$t('msg.pms_name')" clearable></el-input>
               </el-form-item>
               <el-form-item required label="PMS编码">
                  <el-input  class="width-300" v-model="pmsCode" :placeholder="$t('msg.pms_code')" clearable></el-input>
               </el-form-item>
               <el-form-item required label="LOGO">
                  <div class="flex-pic">
                     <img class="old-pic" v-if="oldLogoShow" width="70" height="70" :src="oldLogo" alt="">
                     <el-upload
                              ref="upload"
                              :limit="1"
                              :show-file-list="true"
                              action="#"
                              :auto-upload="false"
                              list-type="picture-card"
                              :on-change="handleChange"
                              :on-preview="handlePictureCardPreview">
                        <i class="el-icon-plus"></i>
                     </el-upload>
                     <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                     </el-dialog>
                  </div>

               </el-form-item>
               <el-form-item label="服务商名称">
                  <el-input  class="width-300" v-model="serviceName" :placeholder="$t('msg.service_name')" clearable></el-input>
               </el-form-item>
               <el-form-item label="联系人">
                  <el-input  class="width-300" v-model="contacts" :placeholder="$t('msg.contact_person')" clearable></el-input>
               </el-form-item>
               <el-form-item label="联系电话">
                  <el-input  class="width-300" v-model="telephone" :placeholder="$t('msg.contact_phone')" clearable></el-input>
               </el-form-item>
               <el-form-item label="电子邮箱">
                  <el-input  class="width-300" v-model="email" :placeholder="$t('msg.e_mail')" clearable></el-input>
               </el-form-item>
               <el-form-item label="联系地址">
                  <el-input  class="width-300" v-model="address" :placeholder="$t('msg.contact_address')" clearable></el-input>
               </el-form-item>
               <el-form-item label="备注信息">
                  <el-input class="width-300 type-textarea" type="textarea" v-model="remark" :placeholder="$t('msg.inp_remark_info')"></el-input>
               </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
               <el-button  @click="resetForm" v-text="$t('msg.reset')">重置</el-button>
               <el-button class="bg-gradient" type="primary" @click="handleSave" v-text="$t('msg.save')">保存</el-button>
            </span>
         </el-dialog>
      </el-row>
   </div>
</template>
<script>
   import { urlObj } from '@/api/interface'
   import common from '@/common/js/common'
   export default {
      data(){
         return {
            tableData: [],        // 表格数据
            isShow: false,        // 是否显示弹窗
            operateType: '',      // 当前操作类型
            total: 0,             // 列表总条目数
            loading: '',          // 表格加载动画
            /* 上传图片 */
            dialogImageUrl: '',   // 放大图显示地址
            dialogVisible: false, // 大图默认显示/隐藏
            refresh: true,        // 刷新组件
            oldLogoShow: false,   // 旧logo显示/隐藏
            oldLogo: '',          // 旧logo地址
            /* 查询列表字段 */
            limit: 0,             // 每页显示数
            page: 1,              // 当前页 默认第一页
            condition: '',        // PMS名称/编码
            /* 添加表单字段 */
            id: '',               // 编辑唯一标识
            file: '',             // 文件资源
            pmsName: '',          // PMS名称
            pmsCode: '',          // PMS编码
            remark: '',           // 备注信息
            serviceName: '',      // 服务商名称
            contacts: '',         // 联系人
            telephone: '',        // 联系电话
            email: '',            // 电子邮箱
            address: '',          // 联系地址
            /* 弹窗提示文本 */
            confirm: '确定',
            cancel: '取消',
            prompt: '提示！',
            add_success: '添加成功！',
            update_success: '修改成功！',
            confirm_remove: '确定移除？',
            remove_success: '移除成功！',
            pms_name_no_null: 'PMS名称不能为空！',
            pms_code_no_null: 'PMS编码不能为空！',
         }
      },
      mounted() {
         this.limit = sessionStorage.getItem('pageSize') * 1 // 每页显示数
         this.getPmsList()
      },
      methods: {
         // PMS列表
         getPmsList(){
            let url = urlObj.getPmsPages
            const param = {
               condition: this.condition,
               limit: this.limit,
               page: this.page
            }
            this.$axios.post(url, param).then((res) => {
               if (res.success) {
                  this.loading = false
                  this.total = res.total
                  let tableData = res.records
                  tableData.forEach(item => {
                     item.logo = item.logo && window.getResourcesHost() + item.logo
                  })
                  this.tableData = tableData
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 查询列表
         handleQuery(bool){
            if (bool) return this.getPmsList()
            this.condition = ''
         },
         // 改变显示条数
         pageChange(num){
            this.limit = num
            this.getPmsList()
         },
         // 改变当前页
         handlePaging(num){
            this.page = num
            this.getPmsList()
         },
         // 获取上传的文件资源
         handleChange(file){
            // 验证文件格式、大小
            const imgFormat = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif'
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!imgFormat) {
               this.$message.error('上传头像图片只能是JPG/PNG/GIF格式!');
               // 强制刷新局部DOM节点
               this.refresh = false
               this.$nextTick(() => {
                  this.refresh = true
               })
               return
            }
            if (!isLt2M) {
               this.$message.error('上传头像图片大小不能超过 2MB!');
               this.refresh = false
               this.$nextTick(() => {
                  this.refresh = true
               })
               return
            }
            this.oldLogoShow = false
            this.file = file.raw
         },
         // 点击图片查看大图
         handlePictureCardPreview(file){
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
         },
         // 添加PMS
         handleAdd(){
            this.isShow = true
            this.operateType = 'add'
         },
         // 编辑PMS
         handleEdit(row){
            if (row.logo){
               this.oldLogoShow = true
               this.oldLogo = row.logo
            }
            this.isShow = true
            this.operateType = 'edit'
            this.pmsName = row.pmsName
            this.pmsCode = row.pmsCode
            this.remark = row.remark
            this.serviceName = row.serviceName
            this.contacts = row.contacts
            this.telephone = row.telephone
            this.email = row.email
            this.address = row.address
            this.id = row.id
         },
         // 重置表单
         resetForm(){
            this.pmsName = ''
            this.pmsCode = ''
            this.remark = ''
            this.serviceName = ''
            this.contacts = ''
            this.telephone = ''
            this.email = ''
            this.address = ''
            this.file = null
            this.oldLogoShow = false
            this.oldLogo = ''
            // 强制刷新上传图片组件
            this.refresh = false
            this.$nextTick(() => {
               this.refresh = true
            })
         },
         // 保存(新建PMS)
         handleSave(){
            // PMS名称
            if (!this.pmsName) {
               this.$alert(this.pms_name_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            // PMS编码
            if (!this.pmsCode) {
               this.$alert(this.pms_code_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            let url = urlObj.addPms
            let param = new FormData()
            if (this.file){
               param.append('file', this.file)
               param.append('module', 'hotel')
               param.append('menu', 'pms')
               param.append('func', 'logo')
               param.append('category', 'logo')
               param.append('isThumb', true)
            }
            param.append('pmsName', this.pmsName)
            param.append('pmsCode', this.pmsCode)
            param.append('remark', this.remark)
            param.append('serviceName', this.serviceName)
            param.append('contacts', this.contacts)
            param.append('telephone', this.telephone)
            param.append('email', this.email)
            param.append('address', this.address)
            if (this.operateType === 'edit'){
               url = urlObj.editPms
               param.append('id', this.id)
            }
            this.$axios.post(url, param, 'file').then((res) => {
               if (res.success) {
                  this.operateType === 'add' ? this.$message({
                     showClose: true,
                     message: this.add_success,
                     type: 'success'
                  }) : this.$message({
                     showClose: true,
                     message: this.update_success,
                     type: 'success'
                  })
                  this.getPmsList()
                  this.handleClose()
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 关闭弹窗
         handleClose(){
            this.isShow = false
            this.resetForm()
         },
         // 删除PMS
         handleDel(id){
            this.$confirm(this.confirm_remove, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'error'
            }).then(() => {
               let url = urlObj.deletePms + `/${id}`
               this.$axios.get(url, {}).then((res) => {
                  if (res.success) {
                     this.$message({
                        showClose: true,
                        message: this.remove_success,
                        type: 'success'
                     })
                     this.getPmsList()
                  }
               }).catch(error => {
                  console.log(error)
               })
            })
         },
  }
}
</script>
<style lang="scss">
   .pms-upload{
      display: flex;
   }
   .pms-upload .el-upload{
      width: 100px;
      height: 100px;
      line-height: 102px;
   }
   .pms-upload .old-pic{
      border-radius: 6px;
      margin-right: 8px;
      width: 100px;
      height: 100px;
      border: solid 1px #c0ccda;
   }
   .pms-upload .el-upload-list li{
      width: 100px!important;
      height: 100px!important;
   }
</style>
